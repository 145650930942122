/* eslint-disable no-undef */
import React, { useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import FullWidthLayout from "../components/Common/FullWidthLayout"
import landingBackground3 from "../assets/images/landingBackground3.svg"
import landingBackground2 from "../assets/images/landingBackground2.svg"
import landingBackgroundCircle from "../assets/images/landingBackgroundCircle.svg"
import landingBackgroundPath from "../assets/images/landingBackgroundPath.svg"
import arrowOne from "../assets/images/arrowOne.svg"
import arrowTwo from "../assets/images/arrowTwo.svg"
import arrowRight from "../assets/icons/arrowRight.svg"
import googleForm from "../assets/icons/appIcons/googleForm.svg"
import googleSheets from "../assets/icons/appIcons/googleSheets.svg"
import campminder from "../assets/icons/appIcons/campminder.svg"
import campbrain from "../assets/icons/appIcons/campbrain.png"
import campsite from "../assets/icons/appIcons/campsite.svg"
import gmail from "../assets/icons/appIcons/gmail.svg"
import slack from "../assets/icons/appIcons/slack.svg"
import mailchimp from "../assets/icons/appIcons/mailchimp.svg"
import sharpspring from "../assets/icons/appIcons/sharpspring.svg"
import zapier from "../assets/icons/appIcons/zapier.svg"
import hubspot from "../assets/icons/appIcons/hubspot.svg"
import salesforce from "../assets/icons/appIcons/salesforce.svg"
import constantcontact from "../assets/icons/appIcons/constantcontact.svg"
import looker from "../assets/icons/appIcons/looker.svg"
import sbm from "../assets/icons/appIcons/sbm.png"
import openai from "../assets/icons/appIcons/openai.svg"
import bettercontact from "../assets/icons/appIcons/bettercontact.png"
import proxycurl from "../assets/icons/appIcons/proxycurl.png"
import mycampapp from "../assets/icons/appIcons/mycampapp.png"
import mautic from "../assets/icons/appIcons/mautic.svg"
import salesloft from "../assets/icons/appIcons/salesloft.svg"
import moreInfo from "../assets/images/moreInfo.svg"
import teamData from "../data/team.json"
import eric from "../assets/images/team/eric.png"
import carl from "../assets/images/team/carl.jpg"
import raj from "../assets/images/team/raj.jpg"
import aashish from "../assets/images/team/aashish.jpg"
import stefan from "../assets/images/team/stefan.jpg"
import moeez from "../assets/images/team/moeez.jpg"
import ali from "../assets/images/team/ali.jpg"
import henry from "../assets/images/team/henry.jpg"
import dinhe from "../assets/images/team/dinhe.jpg"

import testimonialData from "../data/testimonials.json"
import korey from "../assets/images/testimonials/korey.png"
import dayna from "../assets/images/testimonials/dayna.png"
import kush from "../assets/images/testimonials/kush.png"
import ken from "../assets/images/testimonials/ken.png"
import andy from "../assets/images/testimonials/andy.png"
import mia from "../assets/images/testimonials/mia.png"
import angela from "../assets/images/testimonials/angela.png"

import story from "../assets/images/storyPortrait.svg"
import WaitlistForm from "../components/WaitlistForm/WaitlistForm"

const teams = teamData.map((r, i) => {
  r.image = [eric, carl, raj, aashish, stefan, moeez, ali, henry, dinhe][i]
  return r
})

const testimonialList = testimonialData.map((r, i) => {
  r.image = [korey, dayna, kush, ken, andy, mia, angela][i]
  return r
})

export default function Home() {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    try {
      document.body.scrollTo({ top: 0, behavior: "smooth" })
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <FullWidthLayout>
      <div className="Home--Root">
        <div className="headerContainer">
          <div className="content">
            <img src={landingBackgroundCircle} className="landingBackgroundCircle" />
            <div className="textArea">
              <h1 className="title">Integrating and Connecting Your Camp Software</h1>
              <p className="subtitle">Customized for you. Done for you.</p>
              <img src={landingBackground2} className="landingBackground2" />
            </div>
            <WaitlistForm withLogo={false} />
            <img src={landingBackgroundPath} className="landingBackgroundPath" />
          </div>
        </div>
        <section className="section3" id="solutions">
          <div className="content">
            <h3 className="sectionTitle">Your Software Don't Talk To Each Other?</h3>
            <h3 className="sectionTitle">NOW they do!</h3>
            <p className="sectionSubtitle">Example Scenarios and Use-Cases</p>
            <div className="mainArea">
              <div className="row">
                <div className="sample one">
                  <h3 className="title">Registration / Enrollment</h3>
                  <div className="appsStep">
                    <img src={campminder} className="appIcon" /> <span>Enrollment happens in Camp Minder</span>
                  </div>
                  <div className="appsStep">
                    <img src={zapier} className="appIcon" /> <span>Automatically send parents to Zapier</span>
                  </div>
                  <div className="appsStep">
                    <img src={mailchimp} className="appIcon" /> <span>Automatically send parents to Mail Chimp</span>
                  </div>
                  <div className="appsStep">
                    <img src={googleSheets} className="appIcon" />{" "}
                    <span>Automatically send parents to Google Sheets</span>
                  </div>
                  <span>...Or customize it to fit your workflow!</span>
                </div>
                <div className="sample two">
                  <h3 className="title">Registration / Enrollment</h3>
                  <div className="appsStep">
                    <img src={campbrain} className="appIcon" /> <span>Enrollment happens in Camp Brain</span>
                  </div>
                  <div className="appsStep">
                    <img src={salesforce} className="appIcon" /> <span>Automatically send parents to Sales Force</span>
                  </div>
                  <div className="appsStep">
                    <img src={constantcontact} className="appIcon" />{" "}
                    <span>Automatically send parents to Constant Contact</span>
                  </div>
                  <div className="appsStep">
                    <img src={slack} className="appIcon" /> <span>Automatically send you an update on Slack</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="sample one">
                  <h3 className="title">Dashboard / Reporting</h3>
                  <div className="appsStep">
                    <img src={campminder} className="appIcon" /> <span>Every 15 mins, pull data from Camp Minder</span>
                  </div>
                  <div className="appsStep">
                    <img src={googleSheets} className="appIcon" /> <span>Automatically send data to Google Sheets</span>
                  </div>
                  <div className="appsStep">
                    <img src={looker} className="appIcon" /> <span>Automatically send data to Looker Studio</span>
                  </div>
                </div>
                <div className="sample two">
                  <h3 className="title">Staffing</h3>
                  <div className="appsStep">
                    <img src={campbrain} className="appIcon" /> <span>Application happens in Camp Brain</span>
                  </div>
                  <div className="appsStep">
                    <img src={hubspot} className="appIcon" /> <span>Automatically send applicants to Hubspot</span>
                  </div>
                  <div className="appsStep">
                    <img src={sharpspring} className="appIcon" />{" "}
                    <span>Automatically send applicants to Sharp Spring</span>
                  </div>
                  <div className="appsStep">
                    <img src={googleSheets} className="appIcon" />{" "}
                    <span>Automatically send applicants to Google Sheets</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="sample one">
                  <h3 className="title">Transportation</h3>
                  <div className="appsStep">
                    <img src={sbm} className="appIcon" /> <span>Routes update in School Bus Manager</span>
                  </div>
                  <div className="appsStep">
                    <img src={campminder} className="appIcon" /> <span>Automatically send routes to Camp Minder</span>
                  </div>
                  <div className="appsStep">
                    <img src={googleSheets} className="appIcon" />{" "}
                    <span>Automatically send routes to Google Sheets</span>
                  </div>
                </div>
                <div className="sample two">
                  <h3 className="title">Lead Gen</h3>
                  <div className="appsStep">
                    <img src={proxycurl} className="appIcon" />{" "}
                    <span>Every day, find people you can reach out to on ProxyCurl/LinkedIn</span>
                  </div>
                  <div className="appsStep">
                    <img src={bettercontact} className="appIcon" />{" "}
                    <span>Automatically enrich Leads with Better Contact</span>
                  </div>
                  <div className="appsStep">
                    <img src={openai} className="appIcon" /> <span>Automatically generate outreach with OpenAI</span>
                  </div>
                  <div className="appsStep">
                    <img src={googleSheets} className="appIcon" />{" "}
                    <span>Automatically send leads to Google Sheets</span>
                  </div>
                </div>
              </div>
              <div className="moreInfo">
                <img src={moreInfo} className="icon" />
                <div className="column">
                  <h3 className="title">Why The Camp Stack?</h3>
                  <p className="subtitle">
                    We understand Camp use-cases really well and we know how to efficiently and effectively work with
                    Camp Software APIs. We've ran workflows over 1,000,000 times and already dealt with most scenarios.
                    Don't waste time getting stuck!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section2" id="apps">
          <div className="content">
            <h3 className="sectionTitle">Apps We Can Integrate For You</h3>
            <p className="sectionSubtitle">...and much more!</p>
            <div className="mainArea">
              <div className="row">
                <div className="sample one">
                  <h3 className="title">Camp Specific</h3>
                  <div className="appsStep">
                    <img src={campminder} className="appIcon" /> <span>Camp Minder</span>
                  </div>
                  <div className="appsStep">
                    <img src={campbrain} className="appIcon" /> <span>Camp Brain</span>
                  </div>
                  <div className="appsStep">
                    <img src={campsite} className="appIcon" /> <span>Camp Site</span>
                  </div>
                  <div className="appsStep">
                    <img src={mycampapp} className="appIcon" /> <span>My Camp App</span>
                  </div>
                  <div className="appsStep">
                    <img src={sbm} className="appIcon" /> <span>School Bus Manager</span>
                  </div>
                </div>
                <div className="sample two">
                  <h3 className="title">General</h3>
                  <div className="appsStep">
                    <img src={googleSheets} className="appIcon" /> <span>Google Sheets</span>
                  </div>
                  <div className="appsStep">
                    <img src={openai} className="appIcon" /> <span>OpenAI / ChatGPT</span>
                  </div>
                  <div className="appsStep">
                    <img src={googleForm} className="appIcon" /> <span>Google Forms</span>
                  </div>
                  <div className="appsStep">
                    <img src={zapier} className="appIcon" /> <span>Zapier</span>
                  </div>
                  <div className="appsStep">
                    <img src={mailchimp} className="appIcon" /> <span>Mail Chimp</span>
                  </div>
                  <div className="appsStep">
                    <img src={constantcontact} className="appIcon" /> <span>Constant Contact</span>
                  </div>
                  <div className="appsStep">
                    <img src={hubspot} className="appIcon" /> <span>Hubspot</span>
                  </div>
                  <div className="appsStep">
                    <img src={salesforce} className="appIcon" /> <span>Sales Force</span>
                  </div>
                  <div className="appsStep">
                    <img src={sharpspring} className="appIcon" /> <span>Sharp Spring</span>
                  </div>
                  <div className="appsStep">
                    <img src={mautic} className="appIcon" /> <span>Mautic</span>
                  </div>
                  <div className="appsStep">
                    <img src={gmail} className="appIcon" /> <span>Gmail</span>
                  </div>
                  <div className="appsStep">
                    <img src={looker} className="appIcon" /> <span>Looker Studio</span>
                  </div>
                  <div className="appsStep">
                    <img src={slack} className="appIcon" /> <span>Slack</span>
                  </div>
                  <div className="appsStep">
                    <img src={proxycurl} className="appIcon" /> <span>Proxy Curl</span>
                  </div>
                  <div className="appsStep">
                    <img src={bettercontact} className="appIcon" /> <span>Better Contact</span>
                  </div>
                  <div className="appsStep">
                    <img src={salesloft} className="appIcon" /> <span>Sales Loft</span>
                  </div>
                </div>
              </div>
              {/*<img src={arrowOne} className="arrowOne" />*/}
              {/*<img src={arrowTwo} className="arrowTwo" />*/}
              {/*<div className="stepsContainer one">*/}
              {/*  <div className="relativeContainer">*/}
              {/*    <img src={landingBackground3} className="stepsBackground" />*/}
              {/*    <div className="stepCountContainer">1</div>*/}
              {/*    <div className="grayTitle">Consult</div>*/}
              {/*    <div className="stepTitle">Camp Data Expert</div>*/}
              {/*    <div className="stepSubtitle">*/}
              {/*      Explain your Camper or Staff Recruitment flow to a camp consultants. They’ll ensure your needs are*/}
              {/*      clear to the Engineer who will create the code.*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="stepsContainer two">*/}
              {/*  <div className="relativeContainer">*/}
              {/*    <img src={landingBackground3} className="stepsBackground2" />*/}
              {/*    <div className="stepCountContainer">2</div>*/}
              {/*    <div className="grayTitle">Custom Code</div>*/}
              {/*    <div className="stepTitle">Generated by Our API / AI Engineers</div>*/}
              {/*    <div className="stepSubtitle">*/}
              {/*      The Camp Expert & Engineer will take 1-2 weeks to develop the custom solution to deliver your data*/}
              {/*      to the right place and fire any necessary automations.*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="stepsContainer three">*/}
              {/*  <div className="relativeContainer">*/}
              {/*    <img src={landingBackground3} className="stepsBackground" />*/}
              {/*    <div className="stepCountContainer">3</div>*/}
              {/*    <div className="grayTitle">Maintenance</div>*/}
              {/*    <div className="stepTitle">Dashboard</div>*/}
              {/*    <div className="stepSubtitle">*/}
              {/*      You’ll receive documentation and a way to view a summary of the data that has been moved on a daily*/}
              {/*      or weekly basis. Congratulations, you’re free to do more meaningful work now.*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
            <button
              className="primaryButton"
              onClick={() => {
                window.scroll(0, 0)
                navigate("/start")
              }}
            >
              Start Integrating!
            </button>
          </div>
        </section>
        <section className="section3" id="pricing">
          <div className="content">
            <h3 className="sectionTitle">Pricing starts at:</h3>
            <h3 className="sectionTitle">$750 one time setup + $150/m per workflow.</h3>
            <p className="sectionSubtitle">
              *Basic workflows support up to <b>3 destinations</b>.
              <br />
              *Pre-pay for the year/season and get a discount for <b>$125/m</b>.
              <br />*
              <Link to={`${process.env.PUBLIC_URL + "/start"}`}>
                <b>Contact us</b>
              </Link>{" "}
              for custom admin panels, widgets, and real time 2-way syncs.
            </p>
          </div>
        </section>
        <section className="section4" id="about">
          <div className="content">
            <h3 className="sectionTitle">Meet the team behind the screen</h3>
            <p className="sectionSubtitle">Service is first at The Camp Stack. We're here to help!</p>
            <div className="teamsGrid">
              {teams.map(member => (
                <div className="memberItem" key={member?.id}>
                  <img src={member.image} className="image" />
                  <div className="memberName">{member.name}</div>
                  <div className="memberJob">{member.job}</div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="section5" id="story">
          <div className="content">
            <div className="textArea">
              <h3 className="title">Our Story: Marketing & Technology Just for Camps</h3>
              <p className="subtitle">
                Eric grew up at{" "}
                <a href="https://elmwooddaycamp.com/" target="_blank" rel="noreferrer">
                  Elmwood Day Camp
                </a>
                . He held every position from Bus Counselor to Athletics Director. Eric went on to help revitalize
                another day camp to become{" "}
                <a href="https://woodmontdaycamp.com/" target="_blank" rel="noreferrer">
                  Woodmont Day Camp
                </a>
                . After a legacy and re-brand experiences, Eric started a short session overnight girls soccer camp,{" "}
                <a href="https://berkshiresocceracademy.com/" target="_blank" rel="noreferrer">
                  The Berkshire Soccer Academy
                </a>{" "}
                with his wife Liza and Dan Zenkel. While learning how to recruit campers at various types of camps, Eric
                developed an expertise in sales & marketing automation.
              </p>
              <p className="subtitle">
                In 2018, Eric started{" "}
                <a href="https://www.campermachine.com/" target="_blank" rel="noreferrer">
                  Camper Machine
                </a>
                , to share his knowledge with other camps who wanted to leverage the latest technology to customize the
                Camper Machine for their recruitment & retention strategies. However, most existing technologies weren't
                designed for the camping industry.
              </p>
              <p className="subtitle">
                Carl, on the other hand, spent most of his career writing code and building software, providing systems
                and automation to large institutions and startups. Despite working on projects used by millions, he
                desired to create a more significant impact. Carl sought an industry where he could utilize his skills
                to their fullest potential.
              </p>
              <p className="subtitle">
                When Eric and Carl met, they immediately bonded over the problems plaguing the camping world,
                particularly regarding data transfers between software systems. They began assisting several camps in
                automating their systems and syncing their data. Their passion for the work grew, and they became
                determined to devote their time and energy to helping more camps worldwide by creating custom solutions
                the summer camps that want more effective and meaningful work.
              </p>
            </div>
            <img src={story} className="storyPortrait" />
          </div>
          <div className="storyLandscape" />
        </section>
        <section className="section6">
          <div className="gridArea">
            <div className="heading">What others have to say...</div>
            <div className="content">
              {testimonialList.map((r, i) => (
                <div key={i} className="userReviewBox">
                  <div className="rowTitle">
                    <img src={r.image} alt="user" className="userImage" />
                    <p className="title">{r.name}</p>
                  </div>
                  <p className="desc">{r.content}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </FullWidthLayout>
  )
}
