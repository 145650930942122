/* eslint-disable no-undef */
import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { API } from "../../api/API"
import upArrow from "../../assets/icons/scrollToTop.svg"
import footerLogo from "../../assets/icons/logo-full-light.svg"
import messageIcon from "../../assets/icons/message.svg"
import { HashLink } from "react-router-hash-link"

export default function Footer() {
  const [loading, setLoading] = React.useState(false)
  const [subMsg, setSubMsg] = React.useState("")
  const [email, setEmail] = React.useState("")

  const size = useWindowSize()
  const location = useLocation()

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined
    })
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }
      window.addEventListener("resize", handleResize)
      handleResize()
      return () => window.removeEventListener("resize", handleResize)
    }, [])
    return windowSize
  }

  const createSendinBlueContact = async () => {
    setSubMsg("")
    setLoading(true)
    if (!email) {
      return setSubMsg("Email is required.")
    }
    const res = await API.createSendinBlueContact(email)
    if (res.err) {
      setSubMsg(
        "Whoops looks like something happened and " +
          "we can't subscribe you at this time. Shoot us an email at " +
          "support@thecampstack.com and we'll send you a gift :)"
      )
    } else {
      setSubMsg("Subscribed!")
    }
    setLoading(false)
  }

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -110
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" })
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div className="Footer--Root">
      <div className="footer__container">
        <div className="company__links">
          <div className="company_block">
            <img src={footerLogo} alt="logo" className="logo_img" />
            {/*<div className="newsletter">*/}
            {/*  <span className="title">Subscribe to newsletter</span>*/}
            {/*  <div className="input-container" htmlFor="footer-newsletter">*/}
            {/*    <img src={messageIcon} alt="subscribe icon" />*/}
            {/*    <input*/}
            {/*      id="footer-newsletter"*/}
            {/*      type="text"*/}
            {/*      placeholder="Your Email"*/}
            {/*      onChange={e => setEmail(e.target.value)}*/}
            {/*      onKeyDown={e => {*/}
            {/*        if (e.key === "Enter") {*/}
            {/*          createSendinBlueContact()*/}
            {/*        }*/}
            {/*      }}*/}
            {/*    />*/}
            {/*    <button onClick={() => createSendinBlueContact()}>*/}
            {/*      {size.width >= 871 && size.width <= 1090 ? ">" : "Subscribe"}*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*  <span className="errMsg">{subMsg}</span>*/}
            {/*  <span className="subtitle">For the latest updates and your FOMO cure</span>*/}
            {/*</div>*/}
          </div>
          <div className="links_block">
            <div>
              <p className="link__title">Company</p>
              <ul className="link__list">
                <li>
                  <HashLink scroll={el => scrollWithOffset(el)} to={"/#automation"}>
                    Automation
                  </HashLink>
                </li>
                <li>
                  <HashLink scroll={el => scrollWithOffset(el)} to={"/#integration"}>
                    Integration
                  </HashLink>
                </li>
                <li>
                  <HashLink scroll={el => scrollWithOffset(el)} to={"/#about"}>
                    About Us
                  </HashLink>
                </li>
                <li>
                  <Link to={"/terms"}>Terms of Use</Link>
                </li>
                <li>
                  <Link to={"/privacy"}>Privacy Policy</Link>
                </li>
              </ul>
            </div>
            <div>
              <p className="link__title">Follow up</p>
              <ul className="link__list">
                <li>
                  <a href={"https://www.facebook.com/thecampstack"} target="_blank" rel="noopener noreferrer">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href={"https://www.instagram.com/officialcampstack"} target="_blank" rel="noopener noreferrer">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href={"https://www.linkedin.com/company/thecampstack"} target="_blank" rel="noopener noreferrer">
                    Linkedin
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <p className="link__title">Connect</p>
              <ul className="link__list">
                <li>
                  <a href={"mailto:support@thecampstack.com"}>support@thecampstack.com</a>
                </li>
                <li>
                  <a
                    href={"https://cal.services/eric/1hr%3A-Zoom-Video-Call/nVdOyS4gb"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact
                  </a>
                </li>
                <li>
                  <div className="textOnly">Headquarters: Los Angeles, CA</div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="copy_rights">
          <p>Copyright © The Camp Stack 2024. All Rights Reserved.</p>
        </div>
      </div>
      <button type="button" className="scrollToTop" onClick={scrollToTop}>
        <img src={upArrow} className="icon" alt="^" />
      </button>
    </div>
  )
}
