/* eslint-disable no-undef */
import React, { useRef, useEffect } from "react"
import { NavLink, useLocation, useNavigate, Link } from "react-router-dom"
import { BsArrowRight } from "react-icons/bs"
import { AiOutlineRight } from "react-icons/ai"
import { RiMenu5Line } from "react-icons/ri"
import logo from "../../assets/icons/logo-full-light.svg"
import { HashLink } from "react-router-hash-link"

const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const smHeader = useRef(null)
  const smHeaderBtn = useRef(null)

  const toggleSmHeader = () => {
    if (smHeader.current) {
      if (smHeader.current.classList.contains("active")) {
        smHeader.current.classList.remove("active")
        smHeaderBtn.current.classList.remove("active")
      } else {
        smHeader.current.classList.add("active")
        smHeaderBtn.current.classList.add("active")
      }
    }
  }

  const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset
    const yOffset = -110
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" })
  }

  useEffect(() => {
    smHeader.current.classList.remove("active")
    smHeaderBtn.current.classList.remove("active")
  }, [location.hash])

  return (
    <div className="HeaderBar--root">
      <div className="content">
        <div className="mainArea">
          <NavLink className="logobox" to="/">
            <img src={logo} alt="" />
          </NavLink>
          <ul className="navs">
            <li>
              <HashLink scroll={el => scrollWithOffset(el)} id="solutionsLink" className="navLink" to="/#solutions">
                Solutions
              </HashLink>
            </li>
            <li>
              <HashLink scroll={el => scrollWithOffset(el)} id="appsLink" className="navLink" to="/#apps">
                Apps
              </HashLink>
            </li>
            <li>
              <HashLink scroll={el => scrollWithOffset(el)} id="pricingLink" className="navLink" to="/#pricing">
                Pricing
              </HashLink>
            </li>
            <li>
              <HashLink scroll={el => scrollWithOffset(el)} id="aboutLink" className="navLink" to="/#about">
                About
              </HashLink>
            </li>
            <li id="blogLink" className="navLink">
              <a href="https://blog.thecampstack.com">Blog</a>
            </li>
            {/* <button className="borderedButton">Demo</button> */}
            <div className="buttonArea">
              <Link to={`${process.env.PUBLIC_URL + "/start"}`}>
                <button className="btn primaryButton">Learn More</button>
              </Link>
            </div>
          </ul>
        </div>
      </div>

      <div className="nav_btn">
        <button ref={smHeaderBtn} onClick={toggleSmHeader} className="btn btn-primary">
          <RiMenu5Line className="normal" color="#fff" size={25} />
          <AiOutlineRight className="open" color="#fff" size={25} />
        </button>
        <div ref={smHeader} className="navs__sm">
          <div className="nav__box">
            <div className="links">
              <HashLink scroll={el => scrollWithOffset(el)} id="solutionsLinkMobile" className="link" to="/#solutions">
                <span>Solutions</span>
                <BsArrowRight className="arrow_right" />
              </HashLink>
              <HashLink scroll={el => scrollWithOffset(el)} id="appsLinkMobile" className="link" to="/#apps">
                <span>Apps</span>
                <BsArrowRight className="arrow_right" />
              </HashLink>
              <HashLink scroll={el => scrollWithOffset(el)} id="pricingLinkMobile" className="link" to="/#pricing">
                <span>Pricing</span>
                <BsArrowRight className="arrow_right" />
              </HashLink>
              <HashLink scroll={el => scrollWithOffset(el)} id="aboutUsLinkMobile" className="link" to="/#about">
                <span>About Us</span>
                <BsArrowRight className="arrow_right" />
              </HashLink>
              <a className="link" href="https://blog.thecampstack.com">
                <span>Blog</span>
                <BsArrowRight className="arrow_right" />
              </a>
              <div className="buttonArea">
                <button
                  className="btn primaryButton"
                  onClick={() => {
                    toggleSmHeader()
                    navigate("/start")
                  }}
                >
                  Learn More
                </button>
              </div>
            </div>
            <div className="footer">
              <p>Copyright © The Camp Stack 2024. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
