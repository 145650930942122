/* eslint-disable no-undef */
import React, { useEffect } from "react"
import { BsTelephone } from "react-icons/bs"
import { FaRegEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa"
import { FiMapPin } from "react-icons/fi"
import FullWidthLayout from "../components/Common/FullWidthLayout"

const Contact = () => {
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute("src", "https://assets.calendly.com/assets/external/widget.js")
    head.appendChild(script)
  }, [])

  useEffect(() => {
    try {
      document.body.scrollTo({ top: 0, behavior: "smooth" })
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <FullWidthLayout>
      <div className="Contact--Root">
        <div className="left">
          <h3 className="title">Connect With Us</h3>
          <p className="desc">Any questions or feedback? Schedule a call with us!</p>
          <div className="contact__details">
            <div className="item">
              <p>
                <BsTelephone className="icon" />
                626-466-6473 (Text only)
              </p>
            </div>
            <div className="item">
              <p>
                <FaRegEnvelope className="icon" />
                support@thecampstack.com
              </p>
            </div>
            <div className="item">
              <p>
                <FiMapPin className="icon" />
                Los Angeles, CA
              </p>
            </div>
          </div>
          <div className="social__icons">
            <a
              className="icon__box"
              href={"https://www.facebook.com/thecampstack"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF />
            </a>
            <a
              className="icon__box"
              href={"https://www.instagram.com/officialcampstack"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              className="icon__box"
              href={"https://www.linkedin.com/company/thecampstack"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
            <a className="icon__box" href={"mailto:support@thecampstack.com"}>
              <FaRegEnvelope />
            </a>
          </div>
        </div>
        <div className="right">
          <div
            className="calendly-inline-widget"
            data-url="https://calendly.com/carl333/30min"
            style={{ minWidth: "320px", height: "930px" }}
          />
        </div>

        {/* <iframe
          src="https://calendly.com/carl333/30min"
          width="100%"
          height="950"
          scrolling="no"
          frameborder="0"
        ></iframe> */}
      </div>
    </FullWidthLayout>
  )
}

export default Contact
